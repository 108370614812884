import React from 'react';
import { Canvas } from '@react-three/fiber';
import Logo from './Logo/Logo';
import SocialMediaIcons from './SocialMediaIcons/SocialMediaIcons';
import './App.css';

function App() {
  return (
    <div className="app">
      <div className="logo-section">
      <Canvas 
        camera={{ position: [0, 0, 5], fov: 70 }}
        id='canvas-container'
      >
          {/* Ambient light to illuminate the scene */}
          <ambientLight intensity={0.5} />
          {/* Directional light for shadows and directionality */}
          <directionalLight position={[0, 0, 5]} intensity={1} />
          <Logo />
        </Canvas>
      </div>
      <SocialMediaIcons />
    </div>
  );
}


export default App;
