import { FaFacebook, FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa';

function SocialMediaIcons() {
    const iconStyle = { color: 'black', fontSize: '40px' }; // Set the color and size

    return (
        <div className="social-media-icons">
            <a className='social-media-icon' href="https://www.tiktok.com/@olatextil" target="_blank" rel="noreferrer">
                <FaTiktok  style={iconStyle}/>
            </a>
            <a className='social-media-icon' href="https://www.instagram.com/olatextil" target="_blank" rel="noreferrer">
                <FaInstagram style={iconStyle} />
            </a>
            <a 
                className='social-media-icon' 
                href="https://www.facebook.com/olatextil" 
                target="_blank"
                rel="noreferrer" >
                    <FaFacebook style={iconStyle}/>
            </a>
            <a className='social-media-icon' href="https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D%252B524421438669%26data%3DARCglnqD7nUEBCf4k56NY6YWas6CiFl7UYkf8f6V8Rx_I4B8rvaxECiWwiHsGRIQ31aErFGSIPjkHIoNcQHN-bpyaz5KI9LNHgEQcS2GvV_brrts45EWDAk5bMVuN3KQbyUYCebfNZtdzbhMpzehbJU%26source%3DFB_Page%26app%3Dfacebook%26entry_point%3Dpage_cta%26fbclid%3DIwAR3dioJDjiUG5qjvoT3fIYOHHBpAIxujn5Mvuz8lzszozjPCNj8XM907kaA&h=AT3hhEm6XrvtIj1vf2S_XxMnhdwR3HFsu2IkQ-yygVgkgw917mFbD3aifmj98PaPdIVYRpG1OV77wSJ7O8DBIAn3fe58muoDUD-JX90BONbfX8JWoTwurZuy8RQDDhsEaFfafsKA3Kn3JdNqtKcZhnuI" target="_blank" rel="noreferrer">
                <FaWhatsapp style={iconStyle} />
            </a>
        </div>
    );
}

export default SocialMediaIcons;