import React, { useRef, useState, useEffect } from 'react';
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import { TextureLoader, DoubleSide } from 'three';

function Logo() {
  const meshRef = useRef();
  const texture = useLoader(TextureLoader, 'ola_negro.png');
  const { viewport } = useThree();
  const [isDragging, setIsDragging] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [angularVelocity, setAngularVelocity] = useState(0);
  const [startX, setStartX] = useState(0);


  const maxWidth = 7; // Maximum width the logo can have
  const imageAspectRatio = 600 / 432;

  // Calculate the width based on the viewport but constrain it by maxWidth
  const planeWidth = Math.min(viewport.width * 0.8, maxWidth);
  const planeHeight = planeWidth / imageAspectRatio;

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.scale.set(planeWidth, planeHeight, 1);
    }
  }, [planeWidth, planeHeight]);

  const handlePointerDown = (event) => {
    setIsDragging(true);
    setStartX(event.clientX || event.touches[0]?.clientX);
  };

  const handlePointerMove = (event) => {
    if (isDragging) {
      const clientX = event.clientX || event.touches[0]?.clientX;
      const deltaX = (clientX - startX) * 0.005; // Adjust this value for sensitivity
      setStartX(clientX); // Update startX to the current position
      setAngularVelocity(deltaX);
    }
  };

  const handlePointerUp = () => {
    setIsDragging(false);
  };

  useFrame(() => {
    // Damping factor to gradually reduce both rotation and velocity
    const damping = 0.96;
  
    // Spring constant for the restoring force, should be small for a gentle swing
    const springConstant = 0.02;
  
    // Update angular velocity by adding the restoring force and applying damping
    // The restoring force uses the sine of the rotation to simulate a pendulum
    setAngularVelocity(v => (v + springConstant * -Math.sin(rotation)) * damping);
  
    // Update rotation by adding angular velocity
    setRotation(r => r + angularVelocity);
  
    // Apply the rotation to the mesh
    if (meshRef.current) {
      meshRef.current.rotation.z = rotation;
    }
  });
  

  return (
    <mesh
      ref={meshRef}
      position={[0, 0, 0]}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      onPointerOut={handlePointerUp}
      onPointerCancel={handlePointerUp}
    >
      <planeGeometry args={[1, 1]} />
      <meshStandardMaterial map={texture} side={DoubleSide} transparent />
    </mesh>
  );
}

export default Logo;
